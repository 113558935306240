import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const crowdpointPortalId = "7175493";

export interface FormProps {
  formId?: string;
  portalId?: string;
}

declare const window: any;
const Form = (props: FormProps) => {
  const { formId: propsId, portalId } = props;
  const { formId: paramsId } = useParams();
  const formId = propsId ?? paramsId;

  useEffect(() => {
    if (window.hbspt) {
      window.hbspt.forms.create({
        portalId: portalId ?? crowdpointPortalId,
        formId: formId,
        target: "#hubspotForm",
      });
    }
  }, [formId, portalId]);

  return (
    <div
      style={{
        width: "100%",
        margin: "0 auto",
        maxWidth: "1080px",
        padding: "2rem",
      }}
    >
      <div
        id="hubspotForm"
        style={{ borderRadius: "16px", background: "white", padding: "16px" }}
      ></div>
    </div>
  );
};

export default Form;
