import "./App.css";
import { Routes, Route } from "react-router-dom";
import HubspotForm from "./commponents/HubspotForm";
function App() {
  return (
    <Routes>
      <Route
        path="/enroll"
        element={<HubspotForm formId="db3a0186-c910-4399-b06f-01079bad496e" />}
      />
      <Route path="/form/:formId" element={<HubspotForm />} />
      <Route path="/" element={<HubspotForm />} />
    </Routes>
  );
}

export default App;
